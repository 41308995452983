<template>
  <!-- Header -->
  <header class="container flex-v-center header">
    <div class="grid">
      <div class="span12 flex-v-center">
        <a href="/" title="Gå til top" data-gtm-action="header navigation" data-gtm-label="hjem">
          <inline-svg class="logo" :src="prefixAssetUrl(norligLogo)" width="150" height="30" aria-label="Norli logo"
        /></a>

        <nav class="d-flex flex-h-right nav">
          <a
            class="button button--cta ml-4"
            href="/mit-norli"
            data-gtm-action="header navigation"
            data-gtm-label="log på"
            >Log på</a
          >
        </nav>
      </div>
    </div>
  </header>
  <MaintenanceBanner v-if="showMaintenanceBanner" :text="maintenanceText" :classes="maintenanceColor + ' diabetes'" />
  <div class="container" :class="{'my-7': !showMaintenanceBanner}">
    <div class="grid">
      <!-- Text -->
      <div v-inview="{ animate: true, animation: 'fade', offset: offset }" class="span8 move2">
        <div class="span12 flex-v-center mt-6">
          <inline-svg :src="prefixAssetUrl(diabetesLogo)" width="250" aria-label="Diabetes foreningen logo" />
        </div>
        <form @submit.prevent="onSubmit()" autocomplete="false">
          <div class="mt-4">
            <h1 class="text-center">Opret livsforsikring</h1>
            <p class="">
              Som medlem af Diabetesforeningen kan du få en livsforsikring hos Norli til en fordelagtig pris. Med den kan du sikre dine efterladte, hvis du dør, inden du fylder 67 år.
            </p>
            <p>Udfyld formularen herunder, få en pris, og opret din livsforsikring – nemt og hurtigt.</p>
          </div>

          <!-- Main form -->
          <div class="mt-4">
            <div class="grid">
              <div class="span12">
                <form-input id="firstName" v-model="formModel.firstName" :validator="v$.firstName" label="Fornavn" />
              </div>
              <div class="span12">
                <form-input id="lastName" v-model="formModel.lastName" :validator="v$.lastName" label="Efternavn" />
              </div>
              <div class="span8">
                <form-input id="address" v-model="formModel.address" :validator="v$.address" label="Adresse" />
              </div>
              <div class="span4">
                <form-input id="zipCode" v-model="formModel.zipCode" :validator="v$.zipCode" label="Postnr." />
              </div>
              <div class="span12">
                <form-input id="email" v-model="formModel.email" :validator="v$.email" label="E-mail" />
              </div>
              <div class="span12">
                <form-input id="phone" v-model="formModel.phone" :validator="v$.phone" label="Tlf.nr." />
              </div>
              <div class="span12">
                <form-input
                  id="membership"
                  v-model="formModel.membership"
                  :validator="v$.membership"
                  label="Medlemsnr. hos Diabetesforeningen"
                />
              </div>
              <div class="span12">
                <form-input
                  id="cpr"
                  v-model="formModel.cpr"
                  :validator="v$.cpr"
                  label="CPR-nr."
                  @input="onCprInput()"
                />
                <p>
                  <i>
                    For at vi kan oprette din livsforsikring, har vi brug for dit CPR-nr. Læs mere om, hvordan vi behandler dine personoplysninger 
                    <a
                      href="/siteassets/site-images/pdf/politikker/persondatapolitik-norli-liv-og-pension.pdf"
                      target="_blank"
                      >her</a
                    >. Ønsker du et tilbud uden at oplyse dit CPR-nr., er du velkommen til at ringe til os på
                    <a href="tel:38202400">38 20 24 00</a>.</i
                  >
                </p>
              </div>
            </div>
          </div>

          <!-- Text -->
          <div class="mt-4">
            <h2 class="text-center">Lav pris og mulighed for ekstra dækning</h2>
            <p>
              Grunddækningen sikrer dine efterladte 50.000 kr., hvis du dør, inden du fylder 67 år. Prisen for grunddækningen afhænger af din alder og ligger på 120-552 kr. om året.
            </p>
          </div>

          <!-- Summary -->
          <div class="mt-4" v-if="!v$.cpr.$invalid">
            <div class="summary" v-if="pricePerYear !== null">
              <div class="summary__result">Din pris pr. år:</div>
              <div class="summary__result">{{ pricePerYear }} kr.</div>
            </div>
            <div class="summary" v-else>
              <div class="summary__note">
                Ring til os på 38 20 24 20, så finder vi sammen et produkt, der passer til din aldersgruppe.
              </div>
            </div>
          </div>

          <!-- Text -->
          <div class="mt-4">
            <h3 class="text-center">Indtast dine betalingsoplysninger</h3>
            <p class="text-center">Vi sørger for at tilmelde din betaling til Betalingsservice.</p>
          </div>

          <!-- Bank account form -->
          <div class="mt-4">
            <div class="grid">
              <div class="span4">
                <form-input
                  id="bankRegNumber"
                  v-model="formModel.bank.regNumber"
                  :validator="v$.bank.regNumber"
                  label="Reg. nr."
                />
              </div>
              <div class="span8">
                <form-input
                  id="bankAccountNumber"
                  v-model="formModel.bank.accountNumber"
                  :validator="v$.bank.accountNumber"
                  label="Kontonr."
                />
              </div>
            </div>
          </div>

          <!-- Consent -->
          <div class="mt-4">
            <form-checkbox
              id="consent"
              v-model="formModel.consent"
              :value="true"
              :validator="v$.consent"
              label="Jeg er sund og rask (det har ingen betydning, om du har diabetes eller ej)"
            ></form-checkbox>
          </div>

          <!-- Submit -->
          <div class="mt-4">
            <p class="message" v-if="formLoading">Vent venligst</p>
            <p class="message message--success" v-if="formSuccess">Tak for din henvendelse.<br />
              Vi har sendt dig en mail med din forsikringsaftale. Så snart du har underskrevet den, er din livsforsikring oprettet.
            </p>
            <p class="message message--error" v-if="formError">
              Der opstod en fejl under behandlingen af din anmodning - prøv venligst at udfylde formularen senere
            </p>
            <p class="text-center">
              <button class="button button--cta" type="submit" :disabled="pricePerYear === null">Opret</button>
            </p>
          </div>

          <!-- Text -->
          <div class="mt-4">
            <h3 class="text-center">Ønsker du en højere dækning?</h3>
            <p>
              Er du under 60 år, har du mulighed for at sikre dine efterladte en højere udbetaling – helt op til 1 mio. kr. Ønsker du det, så kontakt os for en videre dialog på
              <a href="tel:38202420">38 20 24 20</a> Dette gælder også, hvis du allerede har en grunddækning via Diabetesforeningens aftale.
            </p>
            <p>
              De efterladte, du sikrer med denne forsikring, er dine "nærmeste pårørende". Hvis du ønsker at sikre en anden, så ring til os på <a href="tel:38202420">38 20 24 20</a>, når du har underskrevet aftalen.
            </p>
          </div>

          <!-- Text -->
          <div class="mt-4"></div>
        </form>
      </div>
    </div>
  </div>

  <footer-component />
</template>
  
<script lang="ts">
import MaintenanceBanner from '@/components/MaintenanceBanner/MaintenanceBanner.vue'
declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance
    captchaOnLoad: () => void
  }
}

interface ReCaptchaInstance {
  ready: (cb: () => any) => void
  execute: (key: string, options: ReCaptchaExecuteOptions) => Promise<string>
  render: (id: string, options: ReCaptchaRenderOptions) => any
}

interface ReCaptchaExecuteOptions {
  action: string
}

interface ReCaptchaRenderOptions {
  sitekey: string
  size: 'invisible'
}

interface IBirthDate {
  date: number
  month: number
  year: number
}

interface IPriceGroup {
  min: number
  max: number
  price: number
}
</script>

<script setup lang="ts">
import axios from 'axios'
import { computed, ref, Ref, onMounted, inject } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers, sameAs } from '@vuelidate/validators'

import FormInput from '@/components/Form/InputText.vue'
import FormCheckbox from '@/components/Form/InputCheckbox.vue'
import FooterComponent from '@/components/Footer/Footer.vue'
import norligLogo from '@/assets/svg/norli-logo.svg'
import diabetesLogo from '@/assets/svg/diabetes-logo.svg'
import iconLinkedin from '@/assets/svg/logo-linkedin.svg'

const reCaptchaKey = document.getElementById('app')?.dataset.sitekey || ''
const isRecaptchaReady = ref(false)

const showMaintenanceBanner = window.showBanner;
const maintenanceText = window.bannerText;
const maintenanceColor = window.bannerColor;

const loadScript = (url: string) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.onload = resolve
    script.onerror = reject
    script.src = url
    document.head.appendChild(script)
  })
}

onMounted(async () => {
  try {
    await loadScript(`https://www.google.com/recaptcha/api.js?render=${reCaptchaKey}`)
    window.grecaptcha.ready(() => {
      isRecaptchaReady.value = true
    })
  } catch (e) {
    isRecaptchaReady.value = false
  }
})

const getRecaptchaToken = (): Promise<string> => {
  if (!isRecaptchaReady.value) {
    throw new Error("Captcha can be executed only when it's ready.")
  }
  return window.grecaptcha.execute(reCaptchaKey, { action: 'submit' })
}

const assetUrlPrefix = inject('assetUrlPrefix') as string

const prefixAssetUrl = (url: string): string => {
  if (import.meta.env.DEV) return url

  const filename = url.slice(2)
  return `${assetUrlPrefix}/${filename}`
}

const cprBirthDate: Ref<IBirthDate> = ref({
  date: 0,
  month: 0,
  year: 0,
})
const cprAge: Ref<number> = ref(0)
const pricePerYear: Ref<number | null> = ref(null)

const formModel = ref({
  firstName: '',
  lastName: '',
  address: '',
  zipCode: '',
  email: '',
  phone: '',
  membership: '',
  cpr: '',
  bank: {
    regNumber: '',
    accountNumber: '',
  },
  consent: false,
})

const formValidation = computed(() => {
  return {
    firstName: {
      required: helpers.withMessage('Udfyld venligst dette felt', required),
    },
    lastName: {
      required: helpers.withMessage('Udfyld venligst dette felt', required),
    },
    address: {
      required: helpers.withMessage('Udfyld venligst dette felt', required),
    },
    zipCode: {
      required: helpers.withMessage('Udfyld venligst dette felt', required),
      zipCodeValidator: helpers.withMessage(
        'Indtast et gyldigt postnummer i dette format: XXXX',
        helpers.regex(/^\d{4}$/)
      ),
    },
    email: {
      required: helpers.withMessage('Udfyld venligst dette felt', required),
      email: helpers.withMessage('Indtast en gyldig email-adresse i dette format: navn@domæne.dk', email),
    },
    phone: {
      required: helpers.withMessage('Udfyld venligst dette felt', required),
      phoneValidator: helpers.withMessage(
        'Indtast et gyldigt telefonnummer i dette format: XXXXXXXX',
        helpers.regex(/^\d{8}$/)
      ),
    },
    membership: {
      required: helpers.withMessage('Udfyld venligst dette felt', required),
      membershipValidator: helpers.withMessage(
        'Indtast et gyldigt Medlemsnummer i dette format: XXXXXXXXXX',
        helpers.regex(/^\d{0,10}$/)
      )
    },
    cpr: {
      required: helpers.withMessage('Udfyld venligst dette felt', required),
      cprValidator: helpers.withMessage(
        'Indtast et gyldigt CPR nummer i dette format: XXXXXX-XXXX',
        helpers.regex(/^\d{6}-\d{4}$/)
      ),
    },
    bank: {
      regNumber: {
        required: helpers.withMessage('Udfyld venligst dette felt', required),
        bankRegNumberValidator: helpers.withMessage(
          'Indtast et gyldigt Reg. nr. i dette format: XXXX',
          helpers.regex(/^\d{4}$/)
        ),
      },
      accountNumber: {
        required: helpers.withMessage('Udfyld venligst dette felt', required),
        bankAccountNumberValidator: helpers.withMessage(
          'Indtast et gyldigt Kontonummer i dette format: XXXXXXXXXX',
          helpers.regex(/^\d{2,10}$/)
        ),
      },
    },
    consent: {
      sameAs: helpers.withMessage('Bekræftelse af feltet er påkrævet', sameAs(true)),
    },
  }
})

const v$ = useVuelidate(formValidation, formModel)

const formLoading = ref(false)
const formSuccess = ref(false)
const formError = ref(false)

const onSubmit = async () => {
  if (formLoading.value) {
    return
  }

  const isValid = await v$.value.$validate()

  if (!isValid) {
    return
  }

  formLoading.value = true
  formSuccess.value = false
  formError.value = false

  try {
    const token = await getRecaptchaToken()
    const postResult = await postFormData('/api/norli/v1/diabetesform', token, formModel.value)
    formSuccess.value = true
  } catch (e) {
    formError.value = true
  } finally {
    formLoading.value = false
  }
}

const priceGroups: IPriceGroup[] = [
  {
    min: 18,
    max: 50,
    price: 125,
  },
  {
    min: 51,
    max: 55,
    price: 265,
  },
  {
    min: 56,
    max: 59,
    price: 425,
  },
]

const onCprInput = () => {
  if (v$.value.cpr.$invalid) {
    cprAge.value = 0
    pricePerYear.value = null
    return
  }
  cprBirthDate.value = getBirthDateFromCpr(formModel.value.cpr)
  cprAge.value = getAge(cprBirthDate.value)
  pricePerYear.value = getPricePerYear(cprAge.value)
}

const isInRange = (a: number, b: number, c: number): boolean => {
  return a >= b && a <= c
}

const getBirthDateFromCpr = (cpr: string): IBirthDate => {
  const cprDate = parseInt(cpr.slice(0, 2))
  const cprMonth = parseInt(cpr.slice(2, 4))
  const cprYear = parseInt(cpr.slice(4, 6))
  const cprYearControll = parseInt(cpr.slice(7, 8))
  let cprCentury = '19'

  if (
    ((cprYearControll === 4 || cprYearControll === 9) && isInRange(cprYear, 0, 36)) ||
    (isInRange(cprYearControll, 5, 8) && isInRange(cprYear, 0, 57))
  ) {
    cprCentury = '20'
  }

  if (isInRange(cprYearControll, 5, 8) && isInRange(cprYear, 58, 99)) {
    cprCentury = '18'
  }

  const cprFullYear = parseInt(`${cprCentury}${cprYear < 10 ? '0' : ''}${cprYear}`, 10)

  return {
    date: cprDate,
    month: cprMonth - 1,
    year: cprFullYear,
  }
}

const getAge = (birthDate: IBirthDate): number => {
  const today = new Date()
  const todayDate = today.getDate()
  const todayMonth = today.getMonth()
  const todayYear = today.getFullYear()

  let age = todayYear - birthDate.year

  if (todayMonth < birthDate.month || (todayMonth === birthDate.month && todayDate < birthDate.date)) {
    age--
  }

  return age
}

const getPricePerYear = (age: number): number | null => {
  const priceGroup = priceGroups.find((group) => isInRange(age, group.min, group.max))
  return priceGroup ? priceGroup.price : null
}

const postFormData = (url: string, token: string, formData: any) => {
  return axios.post(url, formData, {
    headers: {
      'x-recaptcha-token': token,
    },
  })
}

const offset = '-100px'
</script>
  
<style lang="scss">
@import '@/assets/scss/imports/colors.scss';
@import '@/assets/scss/imports/sizes.scss';

.summary {
  display: flex;
  justify-content: space-between;
  padding: $spacer-md;
  background-color: $color-primary;
  color: $color-white;

  &__result {
    font-size: 2rem;
  }

  &__note {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
  }
}

.message {
  font-size: 1.25rem;
  text-align: center;

  &--success {
    color: $color-success;
  }

  &--error {
    color: $color-danger;
  }
}

a[href^='tel'] {
  white-space: nowrap;
}
</style>

